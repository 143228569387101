import { SET_USER, RESET_LOGIN_ERROR, LOGIN_ERROR } from "./types";
import { auth } from "../../config/firebase";
import axios from "axios";
import { deleteCookie } from "../../utils/cookies";

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const setNullUser = () => async () => {
  try {
    setAuthToken();
  } catch (error) {
    return error;
  }
};

export const setUser = (user, isFromLogin) => async (dispatch) => {
  try {
    const { token } = await user.getIdTokenResult();
    const sessionDetails = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/auth/generate`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    if (window.location.hostname === "localhost") {
      document.cookie = `_session=${JSON.stringify(
        sessionDetails.data.token,
      )}; path=/`;
    } else {
      const domain = `.${window.location.hostname.split(".")[1]}.${
        window.location.hostname.split(".")[2]
      }`;
      document.cookie = `_session=${JSON.stringify(
        sessionDetails.data.token,
      )}; domain=${domain}; secure; path=/`;
    }

    dispatch({
      type: SET_USER,
      payload: {
        ...sessionDetails.data.user,
        name:
          sessionDetails.data.user.displayName || sessionDetails.data.user.name,
        authorPhotoURL: user.photoURL || sessionDetails.data.user.photoURL,
      },
    });

    if (isFromLogin) {
      return (window.location.href = window.location.origin + "/");
    }
  } catch (error) {
    return dispatch({
      type: LOGIN_ERROR,
      payload: error.message,
    });
  }
};

export const resetLoginError = () => ({
  type: RESET_LOGIN_ERROR,
});

export const logOut = () => async (dispatch) => {
  await auth.signOut().then(() => {
    setAuthToken();
    dispatch({
      type: SET_USER,
      payload: null,
    });
  });
  deleteCookie("user_details");
  deleteCookie("isNewUser");
  const domain = `
        .${window.location.hostname.split(".")[1]}.${
          window.location.hostname.split(".")[2]
        }
    `;
  deleteCookie("_session", domain);
  deleteCookie("_session", "localhost");
  localStorage.removeItem("emailForSignIn");
  window.location.href = window.location.origin + "/login";
};
