import axios from "axios";
import { FEEDBACK_ERROR, FETCH_FEEDBACK } from "./types";

export const fetchFeedback = (skip) => async (dispatch) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/feedbacks?pageSize=10&page=${skip}`
    );
    return dispatch({
      type: FETCH_FEEDBACK,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: FEEDBACK_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

