import React from "react";
import { Alert, Input, Select, Upload, DatePicker } from "antd";
import { beforeThumbnailUpload } from "../../utils/uploadFileToFirebase";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";

import ImgCrop from "antd-img-crop";

const Option = Select.Option;

const CreateUpdateForm = ({
  formError,
  handleInputChange,
  name,
  description,
  isFeatured,
  handleSelectChanger,
  modules,
  moduleIds,
  isFree,
  isDraft,
  badges,
  badgeIds,
  videos,
  introVideoId,
  tracksError,
  thumbnailURL,
  handleUpload,
  thumbnailSURL,
  date,
  publisher,
  preSalesWhatsAppScript,
  preSalesCopy,
}) => {
  return (
    <div>
      {formError && typeof formError === "string" ? (
        <div style={{ marginBottom: 15 }}>
          <Alert type="error" message={formError} />
        </div>
      ) : null}

      {tracksError && typeof tracksError === "string" ? (
        <div style={{ marginBottom: 15 }}>
          <Alert type="error" message={tracksError} />
        </div>
      ) : null}

      <div>
        <label>Name</label>
        <Input
          value={name}
          defaultValue={name}
          onChange={(e) => handleInputChange(e, "name")}
        />
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Description</label>
        <Input.TextArea
          rows={4}
          placeholder="Enter description here"
          value={description}
          defaultValue={description}
          onChange={(e) => handleInputChange(e, "description")}
        />
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Is Featured</label>
        <Select
          value={isFeatured}
          defaultValue={isFeatured}
          style={{ width: "100%" }}
          placeholder="é apresentado"
          onChange={(val) => handleSelectChanger(val, "isFeatured")}
        >
          <Option value="true">true</Option>
          <Option value="false">false</Option>
        </Select>
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Is Free</label>
        <Select
          value={isFree}
          defaultValue={isFree}
          style={{ width: "100%" }}
          placeholder="true"
          onChange={(val) => handleSelectChanger(val, "isFree")}
        >
          <Option value="true">true</Option>
          <Option value="false">false</Option>
        </Select>
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Is Draft</label>
        <Select
          value={isDraft}
          defaultValue={isDraft}
          style={{ width: "100%" }}
          placeholder="true"
          onChange={(val) => handleSelectChanger(val, "isDraft")}
        >
          <Option value="true">true</Option>
          <Option value="false">false</Option>
        </Select>
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Publisher</label>
        <Input
          value={publisher}
          defaultValue={publisher}
          onChange={(e) => handleInputChange(e, "publisher")}
        />
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Date</label>
        <DatePicker
          value={moment(date || new Date())}
          defaultValue={moment(new Date())}
          onChange={(date) => handleInputChange(date, "date")}
          style={{ width: "100%" }}
        />
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Badges</label>
        <Select
          allowClear
          value={badgeIds}
          defaultValue={badgeIds}
          style={{ width: "100%" }}
          placeholder=""
          onChange={(val) => handleSelectChanger(val, "isBadges")}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {badges && badges.length > 0
            ? badges.map((badge) => {
                return (
                  <Option value={badge.id} key={badge.id}>
                    {badge.name}
                  </Option>
                );
              })
            : null}
        </Select>
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Modules</label>
        <Select
          allowClear
          mode="multiple"
          value={moduleIds}
          defaultValue={moduleIds}
          style={{ width: "100%" }}
          placeholder=""
          onChange={(val) => handleSelectChanger(val, "isModules")}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {modules && modules.length > 0
            ? modules.map((module, idx) => {
                return (
                  <Option value={module.id} key={module.id}>
                    {module.name}
                  </Option>
                );
              })
            : null}
        </Select>
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Thumbnail Website</label>
        <div className="thumbnail-container">
          <ImgCrop
            grid
            rotate
            aspect={2 / 3}
            quality={0.8}
            crop={{ x: 0, y: 0 }}
          >
            <Upload
              name="thumbnail"
              listType="picture-card"
              accept="image/png, image/jpg, image/jpeg"
              showUploadList={false}
              beforeUpload={beforeThumbnailUpload}
              onChange={(upload) => handleUpload(upload, "website")}
            >
              {thumbnailSURL ? (
                <img
                  src={thumbnailSURL}
                  alt="avatar"
                  style={{ width: "100%", height: "200px" }}
                />
              ) : (
                <PlusOutlined />
              )}
            </Upload>
          </ImgCrop>
        </div>
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Thumbnail APP</label>
        <div className="thumbnail-container">
          <ImgCrop
            grid
            rotate
            aspect={16 / 9}
            quality={0.8}
            crop={{ x: 0, y: 0 }}
          >
            <Upload
              name="thumbnail"
              listType="picture-card"
              accept="image/png, image/jpg, image/jpeg"
              showUploadList={false}
              beforeUpload={beforeThumbnailUpload}
              onChange={(upload) => handleUpload(upload)}
            >
              {thumbnailURL ? (
                <img
                  src={thumbnailURL}
                  alt="avatar"
                  style={{ width: "100%", height: "200px" }}
                />
              ) : (
                <PlusOutlined />
              )}
            </Upload>
          </ImgCrop>
        </div>
      </div>

      <div style={{ marginTop: 25 }}>
        <label>Intro Video</label>
        <Select
          allowClear
          showSearch
          value={introVideoId}
          defaultValue={introVideoId}
          style={{ width: "100%" }}
          placeholder=""
          onChange={(val) => handleSelectChanger(val, "introVideo")}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {videos && videos.length > 0
            ? videos.map((vid, idx) => {
                return (
                  <Option value={vid.id} key={vid.id}>
                    {vid.name}
                  </Option>
                );
              })
            : null}
        </Select>
      </div>
      <div style={{ marginTop: 25 }}>
        <label>Pre Sales WhatsApp Script</label>
        <Input.TextArea
          value={preSalesWhatsAppScript}
          defaultValue={preSalesWhatsAppScript}
          onChange={(e) => handleInputChange(e, "preSalesWhatsAppScript")}
        />
      </div>
      <div style={{ marginTop: 25 }}>
        <label>Pre Sales Copy</label>
        <Input
          value={preSalesCopy}
          defaultValue={preSalesCopy}
          onChange={(e) => handleInputChange(e, "preSalesCopy")}
        />
      </div>
    </div>
  );
};
export default CreateUpdateForm;
