import React from "react";
import { Alert, Input, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const DiscordServerForm = (props) => {
  const {
    handleInputChange,
    username,
    email,
    isDiscordServer,
    setIsDiscordServer,
    formError,
  } = props;

  const onChange = (checked) => {
    setIsDiscordServer(checked);
  };

  return (
    <div>
      {formError ? (
        <div style={{ marginBottom: 25 }}>
          <Alert type="error" message={formError} />
        </div>
      ) : null}

      <div 
        style={{ 
          marginTop: 25, 
          display: "flex", 
          alignItems: "center", 
          gap: 10 
        }}
      >
        <Switch 
          defaultValue={isDiscordServer}
          defaultChecked={isDiscordServer}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />} 
          onChange={onChange} 
        />
        <label>{isDiscordServer ? "Join Discord" : "Remove Discord"}</label>
      </div>

      {isDiscordServer 
        ? (
          <div style={{ marginTop: 25 }}>
            <label>Username</label>
            <Input
              value={username}
              defaultValue={username}
              onChange={(e) => handleInputChange(e, "username")}
            />
          </div>
        ) 
        : (
          <div style={{ marginTop: 25 }}>
            <label>Email</label>
            <Input
              value={email}
              defaultValue={email}
              onChange={(e) => handleInputChange(e, "email")}
            />
          </div>
        )
      }
    </div>
  );
};

export default DiscordServerForm;
