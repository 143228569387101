import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import authReducer from "./authReducer";
import postsReducer from "./postsReducer";
import authorReducer from "./authorReducer";
import graficosReducer from "./graficosReducer";
import indicatorsReducer from "./indicatorsReducer";
import fundamentosReducer from "./fundamentosReducer";
import referralsReducer from "./referralsReducer";
import profilesReducer from "./profilesReducer";
import videoReducer from "./videoReducer";
import permissionsReducer from "./permissionsReducer";
import tracksReducer from "./tracksReducer";
import badgeReducer from "./badgeReducer";
import pdfReducer from "./pdfReducer";
import tagsReducer from "./tagsReducer";
import feedbackReducer from "./feedbackReducer";
import shortLinksReducer from "./shortLinksReducer";
import coinsListReducer from "./coinsListReducer";
import web3Reducer from "./web3Reducer";
import MKTEmailReducer from "./MKTEmailReducer";

export default combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  auth: authReducer,
  posts: postsReducer,
  authors: authorReducer,
  graficos: graficosReducer,
  fundamentos: fundamentosReducer,
  indicators: indicatorsReducer,
  referrals: referralsReducer,
  profiles: profilesReducer,
  videos: videoReducer,
  permissions: permissionsReducer,
  tracks: tracksReducer,
  badges: badgeReducer,
  pdfs: pdfReducer,
  tags: tagsReducer,
  feedback: feedbackReducer,
  shortLinks: shortLinksReducer,
  coinsList: coinsListReducer,
  web3List: web3Reducer,
  MKTEmails: MKTEmailReducer,
});
