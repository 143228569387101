import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Col, Row, Tag } from "antd";
import { connect } from "react-redux";
import { Table, Modal } from "antd";
import CuratedForm from "./Components/CuratedForm";
import {
  getCuratedProfiles,
  createCuratedProfile,
  updateCuratedProfile,
  deleteCuratedProfile,
  updateCoin,
  deleteCoin,
  createCoin,
} from "../../store/actions/profilesActions";
import DeleteBlocker from "./Components/DeleteBlocker";
import CoinForm from "./Components/CoinForm";

const CuratedProfiles = (props) => {
  const curatedProfileColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Starting Date",
      dataIndex: "startingDate",
      key: "startingDate",
      render: (startingDate) => (
        <p>{moment(startingDate).format("DD MMM YYYY hh:mm")}</p>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Archived",
      dataIndex: "isArchived",
      key: "isArchived",
      render: (isArchived) => (
        <Tag color={isArchived === "true" ? "red" : "green"}>
          {isArchived.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Archived Date",
      dataIndex: "archiveDate",
      key: "archiveDate",
      render: (archiveDate) => (
        <p>
          {archiveDate ? moment(archiveDate).format("DD/MMM/YYYY hh:mm") : ""}
        </p>
      ),
    },
    {
      title: "Coins",
      dataIndex: "coins",
      key: "coins",
      render: (coins) => <p>{coins?.length}</p>,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Row gutter={4}>
            <Col>
              <Button
                type="ghost"
                onClick={() => showModal("createCoin", record)}
              >
                Add Coin
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                onClick={() => showModal("editProfile", record)}
              >
                Edit
              </Button>
            </Col>

            <Col>
              <Button danger onClick={() => showModal("deleteProfile", record)}>
                Delete
              </Button>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  const coinColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Ticker",
      dataIndex: "ticker",
      key: "ticker",
    },
    {
      title: "Risco",
      dataIndex: "risco",
      key: "risco",
    },
    {
      title: "Initial Date",
      dataIndex: "initialDate",
      key: "initialDate",
      render: (initialDate) => (
        <p>{moment(initialDate).format("DD MMM YYYY hh:mm")}</p>
      ),
    },
    {
      title: "Proportion",
      dataIndex: "proportion",
      key: "proportion",
      render: (propotion) => (
        <p>
          {propotion}
          {propotion ? "%" : ""}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Row gutter={4}>
            <Col>
              <Button
                type="primary"
                onClick={() => showModal("editCoin", record)}
              >
                Edit
              </Button>
            </Col>

            <Col>
              <Button danger onClick={() => showModal("deleteCoin", record)}>
                Delete
              </Button>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  const {
    createCuratedProfile,
    getCuratedProfiles,
    curatedProfiles,
    curatedProfile,
    updateCuratedProfile,
    deleteCuratedProfile,
    coin,
    createCoin,
    deleteCoin,
    updateCoin,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type, setType] = useState("createCoin");
  const [modalTitle, setModalTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [profilePayload, setProfilePayload] = useState({
    name: "",
    startingDate: "",
    tags: [],
    description: "",
    tickers: "",
    isArchived: "false",
    link: "",
  });

  const [coinPayload, setCoinPayload] = useState({
    ticker: "",
    risco: "",
    initialDate: "",
    info: "",
    proportion: 0,
    link: "",
  });

  useEffect(() => {
    getCuratedProfiles();
  }, [getCuratedProfiles]);

  const showModal = (type, record) => {
    if (type === "createProfile") {
      setType("createProfile");
      setModalTitle("Create curated profile");
    }

    if (type === "editProfile" && record) {
      setProfilePayload(record);
      setType("editProfile");
      setModalTitle("Edit curated profile");
    }

    if (type === "deleteProfile" && record) {
      setProfilePayload(record);
      setType("deleteProfile");
      setModalTitle(`Delete ${record.name}`);
    }

    if (type === "createCoin" && record) {
      setProfilePayload(record);
      setType("createCoin");
      setModalTitle("Create coin");
    }

    if (type === "editCoin" && record) {
      setCoinPayload(record);
      setType("editCoin");
      setModalTitle("Edit coin");
    }

    if (type === "deleteCoin" && record) {
      setCoinPayload(record);
      setType("deleteCoin");
      setModalTitle("Delete coin");
    }

    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setLoading(true);

    if (type === "createProfile") {
      await createCuratedProfile(profilePayload);
    }

    if (type === "editProfile") {
      await updateCuratedProfile(profilePayload);
    }

    if (type === "deleteProfile") {
      await deleteCuratedProfile(profilePayload);
    }

    if (type === "createCoin") {
      await createCoin({
        ...coinPayload,
        curatedProfile: profilePayload.id,
        risco: coinPayload.risco ? coinPayload.risco : "Medio",
      });
    }

    if (type === "editCoin") {
      await updateCoin(coinPayload);
    }

    if (type === "deleteCoin") {
      await deleteCoin(coinPayload);
    }

    setLoading(false);
    setIsModalVisible(false);
    await getCuratedProfiles();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Button type="primary" onClick={() => showModal("createProfile")}>
        Create Curated Profile
      </Button>

      <div style={{ marginTop: 20 }}>
        <Table
          columns={curatedProfileColumns}
          expandable={{
            defaultExpandAllRows: false,
            expandedRowRender: (record) => (
              <Table columns={coinColumns} dataSource={record.coins} />
            ),
          }}
          dataSource={curatedProfiles}
        />
      </div>

      <Modal
        title={modalTitle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger={type === "deleteProfile" ? true : false}
            loading={loading}
            onClick={handleOk}
          >
            {type === "deleteProfile" ? "Delete" : "Submit"}
          </Button>,
        ]}
      >
        {type === "createProfile" || type === "editProfile" ? (
          <CuratedForm
            type={type}
            payload={profilePayload}
            handlePayloadChange={setProfilePayload}
            response={curatedProfile}
          />
        ) : type === "deleteProfile" || type === "deleteCoin" ? (
          <DeleteBlocker type={type} />
        ) : type === "createCoin" || type === "editCoin" ? (
          <CoinForm
            type={type}
            payload={coinPayload}
            handlePayloadChange={setCoinPayload}
            response={coin}
          />
        ) : null}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fundamentos: state.fundamentos.fundamentos,
  curatedProfiles: state.profiles.curatedProfiles,
  curatedProfile: state.profiles.curatedProfile,
  coin: state.profiles.coin,
});

export default connect(mapStateToProps, {
  getCuratedProfiles,
  createCuratedProfile,
  updateCuratedProfile,
  deleteCuratedProfile,
  updateCoin,
  deleteCoin,
  createCoin,
})(CuratedProfiles);
