import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row, Table, Avatar, Input } from "antd";
import CreateUpdateForm from "./CreateUpdateForm";
import DeleteForm from "./DeleteForm";
import { connect } from "react-redux";
import {
  getBase64,
  uploadOtherFilesToFirebase,
} from "../../utils/uploadFileToFirebase";
import {
  createBadge,
  deleteBadge,
  updateBadge,
  fetchBadges,
} from "../../store/actions/badgesActions";
import { searchTrack } from "../../store/actions/trackActions";

const { Search } = Input;

const Badges = ({
  badges,
  fetchBadges,
  updateBadge,
  deleteBadge,
  createBadge,
  badgeError,
  searchTrack,
  searchResult,
}) => {
  const [formError] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("createBadge");
  const [name, setName] = useState("");
  const [iconType, setIconType] = useState("icon16x");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [badgeId, setBadgeId] = useState(null);
  const [icons, setIcons] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedResults, setSearchedResults] = useState([]);

  useEffect(() => {
    fetchBadges();
  }, [fetchBadges]);

  useEffect(() => {
    if (searchResult && searchText) {
      setSearchedResults(searchResult.badges || []);
    }

    if (!searchText || searchText === "") {
      setSearchedResults([]);
    }
  }, [searchResult, searchText]);

  const openModal = (modalType, record) => {
    setIsModalVisible(true);
    setType(modalType);
    setName(record.name);
    setThumbnailURL(
      record.icons !== null ? Object.values(record.icons)[0] : "",
    );
    setBadgeId(record.id);
    setIcons(record.icons);
  };

  const clearFormData = () => {
    setName("");
    setThumbnail(null);
    setThumbnailURL("");
  };

  const closeModal = () => {
    setIsModalVisible(false);
    clearFormData();
  };

  const handleOk = async () => {
    setLoading(true);

    if (type === "createBadge") {
      const fileURL = await uploadOtherFilesToFirebase(
        thumbnail.file,
        "badges",
      );
      const finalPayload = {
        name,
        icons: {
          [iconType]: fileURL,
        },
      };
      await createBadge(finalPayload);
    }

    if (type === "editBadge") {
      let fileURL;
      if (thumbnail && thumbnailURL) {
        fileURL = await uploadOtherFilesToFirebase(thumbnail.file, "badges");
      } else {
        fileURL = thumbnailURL;
      }
      const finalPayload = {
        name,
        icons: {
          ...icons,
          [iconType]: fileURL,
        },
      };
      await updateBadge(finalPayload, badgeId);
    }

    if (type === "deleteBadge") {
      await deleteBadge(badgeId);
    }

    setLoading(false);
    clearFormData();
    closeModal();
    await fetchBadges();
  };

  const handleUpload = (upload) => {
    getBase64(upload.file.originFileObj, (imageUrl) => {
      setThumbnail(upload);
      setThumbnailURL(imageUrl);
    });
  };

  const handleIconChange = (value) => {
    setIconType(value);
  };

  const handleInputChange = (event, eventType) => {
    if (eventType === "name") {
      setName(event.target.value);
    }
  };

  const onSearch = async (value) => {
    if (value) {
      await searchTrack(value);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: "Icon",
      dataIndex: "icons",
      key: "icons",
      render: (_, record) => (
        <div>
          <Avatar
            size="large"
            shape="square"
            src={record.icons !== null ? Object.values(record.icons)[0] : ""}
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Acquired",
      dataIndex: "quantityFinished",
      key: "acquired",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.acquired - b.acquired,
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Row gutter={4}>
            <Col>
              <Button
                type="primary"
                onClick={() => openModal("editBadge", record)}
              >
                Edit
              </Button>
            </Col>

            <Col>
              <Button danger onClick={() => openModal("deleteBadge", record)}>
                Delete
              </Button>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div>
        <Row gutter={4}>
          <Col>
            <Button type="primary" onClick={() => openModal("createBadge")}>
              Create Badge
            </Button>
          </Col>

          <Col>
            <Search
              value={searchText}
              placeholder="Search here.."
              onSearch={onSearch}
              style={{ width: 200 }}
              onChange={handleSearchChange}
            />
          </Col>
        </Row>
      </div>

      {formError ? (
        <div style={{ marginTop: 20 }}>
          {" "}
          <Alert message={formError} type="error" />{" "}
        </div>
      ) : (
        ""
      )}

      <div style={{ marginTop: 20 }}>
        <Table
          columns={columns}
          dataSource={
            searchText && searchedResults.length > 0
              ? searchedResults
              : Array.isArray(badges)
              ? badges
              : []
          }
        />
      </div>

      <Modal
        title={
          type === "createBadge"
            ? "Create Badge"
            : type === "editBadge"
            ? "Edit Badge"
            : type === "deleteBadge"
            ? "Delete Badge"
            : "Insert something here"
        }
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger={type === "deleteBadge"}
            loading={loading}
            onClick={handleOk}
          >
            {type === "createBadge"
              ? "Create"
              : type === "editBadge"
              ? "Update"
              : type === "deleteBadge"
              ? "Delete"
              : "Done"}
          </Button>,
        ]}
      >
        {type === "createBadge" || type === "editBadge" ? (
          <CreateUpdateForm
            name={name}
            iconType={iconType}
            handleIconChange={handleIconChange}
            thumbnailURL={thumbnailURL}
            handleUpload={handleUpload}
            handleInputChange={handleInputChange}
            badgeError={badgeError}
          />
        ) : type === "deleteBadge" ? (
          <DeleteForm badge={name} />
        ) : (
          <div>No form type selected</div>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  badges: state.badges.badges,
  badgeError: state.badges.badgeError,
  searchResult: state.tracks.searchResult,
  searchError: state.tracks.searchError,
});

export default connect(mapStateToProps, {
  createBadge,
  deleteBadge,
  updateBadge,
  fetchBadges,
  searchTrack,
})(Badges);
