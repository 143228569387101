import {
  GET_MKT_EMAILS,
  GET_MKT_EMAIL,
  ADD_MKT_EMAIL,
  DELETE_MKT_EMAIL,
  UPDATE_MKT_EMAIL,
} from "./types";
import history from "../../history";
import axios from "axios";
import { getCookie } from "../../utils/cookies";

export const getMKTEmails =
  (category) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/mkt-email-templates`,
    );
    dispatch({
      type: GET_MKT_EMAILS,
      payload: data.data.email_templates,
    });
  };

export const getMKTEmail = (id) => async (dispatch) => {
  try {
    const token = getCookie("_session");
    axios.defaults.headers.common["Authorization"] =
      `Bearer ${JSON.parse(token)}`;
    const doc = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/mkt-email-templates/${id}`,
    );
    return dispatch({
      type: GET_MKT_EMAIL,
      payload: doc.data.email_template,
    });
  } catch (error) {
    return error;
  }
};

export const createMKTEmail =
  ({ name, subject, pplans, data, body }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const token = getCookie("_session");
      axios.defaults.headers.common["Authorization"] =
        `Bearer ${JSON.parse(token)}`;
      const newMKTEmail = {
        name,
        subject,
        data,
        pplans,
      };

      const doc = await axios.post(
        `${process.env.REACT_APP_GOLANG_API}/api/v2/mkt-email-templates`,
        { ...newMKTEmail, body },
      );

      dispatch({
        type: ADD_MKT_EMAIL,
        payload: doc.MKTEmail,
      });

      history.push("/mkt-emails");
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  };

export const updMKTEmail =
  (id, updMKTEmail, body) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const MKTEmail = { id, ...updMKTEmail, body: body };
      const token = getCookie("_session");
      axios.defaults.headers.common["Authorization"] =
        `Bearer ${JSON.parse(token)}`;
      await axios.patch(
        `${process.env.REACT_APP_GOLANG_API}/api/v2/mkt-email-templates/${id}`,
        MKTEmail,
      );
      dispatch({
        type: UPDATE_MKT_EMAIL,
        payload: MKTEmail,
      });

      history.push("/mkt-emails");
    } catch (error) {
      return error;
    }
  };

export const deleteMKTEmail =
  (id) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const token = getCookie("_session");
      axios.defaults.headers.common["Authorization"] =
        `Bearer ${JSON.parse(token)}`;
      await axios.delete(
        `${process.env.REACT_APP_GOLANG_API}/api/v2/mkt-email-templates/${id}`,
      );

      dispatch({
        type: DELETE_MKT_EMAIL,
        payload: id,
      });
    } catch (error) {
      return error;
    }
  };
