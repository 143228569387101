/* eslint-disable no-extend-native */
import { 
  GET_REFERRALS, 
  REFERRALS_ERROR, 
  SEARCH_REFERRALS_SUCCESS, 
  SEARCH_REFERRALS_ERROR,
 } from "./types";
import axios from "axios";

const defaultCoupon = "promo_1PydiqBJX7IPpnVe7CtGexe2";

// eslint-disable-next-line
export const getReferrals = (couponCode) => async (dispatch) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/referral/iugu?coupon=${couponCode || defaultCoupon}`,
    );
    return dispatch({
      type: GET_REFERRALS,
      payload: data.data.invoices,
    });
  } catch (error) {
    return dispatch({
      type: REFERRALS_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const searchReferrals = (searchText, dates) => async (dispatch) => {
  try {
    let url = `${process.env.REACT_APP_GOLANG_API}/api/v2/referral/iugu?`;

    if (searchText) {
      url += `coupon=${searchText}`;
    } else {
      url += `coupon=${defaultCoupon}`;
    }

    if (dates && dates.length === 2) {
      url += `&paid_at_to=${dates[1]}&paid_at_from=${dates[0]}`;
    }

    const data = await axios.get(url);
    
    return dispatch({
      type: SEARCH_REFERRALS_SUCCESS,
      payload: data.data.invoices,
    });
  } catch (error) {
    return dispatch({
      type: SEARCH_REFERRALS_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};
