import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  ContainerOutlined,
  VideoCameraAddOutlined,
  UsergroupAddOutlined,
  CopyOutlined,
  HomeOutlined,
  RadarChartOutlined,
  FolderOpenOutlined,
  PlaySquareOutlined,
  BookOutlined,
  FilePdfOutlined,
  WechatOutlined,
  LinkOutlined,
  UnorderedListOutlined,
  GlobalOutlined,
} from "@ant-design/icons";

const { Sider: AntSider } = Layout;

const Sider = ({ showMenu, user }) => {
  return (
    <AntSider className="sider" trigger={null} collapsible collapsed={showMenu}>
      <div className="logo" />
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["Home"]}>
        <Menu.Item key="Home">
          <Link to="/">
            <HomeOutlined />
            <span>Home</span>
          </Link>
        </Menu.Item>

        {user.clearanceLevel === "admin" || user.clearanceLevel === "author" ? (
          <Menu.Item key="posts">
            <Link to="/posts">
              <CopyOutlined />
              <span>Posts</span>
            </Link>
          </Menu.Item>
        ) : null}

        {user.clearanceLevel === "admin" || user.clearanceLevel === "author" ? (
          <Menu.Item key="mkt-email">
            <Link to="/mkt-emails">
              <CopyOutlined />
              <span>Email marketing</span>
            </Link>
          </Menu.Item>
        ) : null}

        {user.clearanceLevel === "admin" || user.clearanceLevel === "author" ? (
          <Menu.Item key="shortLinks">
            <Link to="/short-links">
              <LinkOutlined />
              <span>Links dinâmicos</span>
            </Link>
          </Menu.Item>
        ) : null}

        {user.clearanceLevel === "admin" || user.clearanceLevel === "videoCreator" ? (
          <Menu.Item key="videos">
            <Link to="/videos">
              <VideoCameraAddOutlined />
              <span>Videos</span>
            </Link>
          </Menu.Item>
        ) : null}

        {user.clearanceLevel && user.clearanceLevel === "admin" ? (
          <Menu.Item key="curated-profiles">
            <Link to="/curated-profiles">
              <RadarChartOutlined />
              <span>Curated Profiles</span>
            </Link>
          </Menu.Item>
        ) : null}

        {user.clearanceLevel && 
        (user.clearanceLevel === "admin" || user.clearanceLevel === "sales") ? (
          <Menu.Item key="referral">
            <Link to="/referral">
              <ContainerOutlined />
              <span>Referral</span>
            </Link>
          </Menu.Item>
        ) : null}

        {(user.clearanceLevel && user.clearanceLevel === "admin") ||
        user.clearanceLevel === "videoCreator" ? (
          <Menu.Item>
            <Link to="/tracks">
              <FolderOpenOutlined />
              <span>Tracks</span>
            </Link>
          </Menu.Item>
        ) : null}

        {(user.clearanceLevel && user.clearanceLevel === "admin") ||
        user.clearanceLevel === "videoCreator" ? (
          <Menu.Item>
            <Link to="/modules">
              <PlaySquareOutlined />
              <span>Modules</span>
            </Link>
          </Menu.Item>
        ) : null}

        {user.clearanceLevel && user.clearanceLevel === "admin" ? (
          <Menu.Item>
            <Link to="/badges">
              <BookOutlined />
              <span>Badges</span>
            </Link>
          </Menu.Item>
        ) : null}

        {user.clearanceLevel && user.clearanceLevel === "admin" ? (
          <Menu.Item>
            <Link to="/coins-list">
              <UnorderedListOutlined />
              <span>Coins List</span>
            </Link>
          </Menu.Item>
        ) : null}

        {user.clearanceLevel && user.clearanceLevel === "admin" ? (
          <Menu.Item>
            <Link to="/web3">
              <GlobalOutlined />
              <span>Web3</span>
            </Link>
          </Menu.Item>
        ) : null}

        {user.clearanceLevel && user.clearanceLevel === "admin" ? (
          <Menu.Item>
            <Link to="/pdfs">
              <FilePdfOutlined />
              <span>Pdfs</span>
            </Link>
          </Menu.Item>
        ) : null}

        {(user.clearanceLevel && user.clearanceLevel === "admin") ||
        user.clearanceLevel === "manager" ? (
          <Menu.Item>
            <Link to="/feedback">
              <WechatOutlined />
              <span>Feedback</span>
            </Link>
          </Menu.Item>
        ) : null}

        {user.clearanceLevel && user.clearanceLevel === "admin" ? (
          <Menu.Item>
            <Link to="/permissions">
              <UsergroupAddOutlined />
              <span>User Management</span>
            </Link>
          </Menu.Item>
        ) : null}
      </Menu>
    </AntSider>
  );
};

export default Sider;
