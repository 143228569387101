import React, { useState } from "react";
import { Alert, Select, Input, Button } from "antd";

const { Option } = Select;
const SubscriptionForm = (props) => {
  const {
    pplan,
    formError,
    subscription,
    feedback,
    closeModal,
    message,
    updateUserPPlan,
    userId,
  } = props;

  const [newPPlan, setPPlan] = useState("");

  const handleInputChange = (e) => {
    setPPlan(parseInt(e));
  };

  const pplanNames = {
    0: "Gratuito",
    2: "Light Quadri",
    3: "Light Anual",
    4: "Pro Quadri",
    5: "Pro Anual",
    6969: "Vitalício",
  };

  const editPPlan = () => {
    updateUserPPlan({ id: userId, pplan: newPPlan })
      .then(() => {
        message.success("Atualizado sucesso.");
      })
      .catch((error) => {
        message.error("Erro ao atualizar.");
      });
  };

  return (
    <div>
      {formError ? (
        <div style={{ marginBottom: 25 }}>
          <Alert type="error" message={formError} />
        </div>
      ) : null}

      <div style={{ marginTop: 25 }}>
        <label>PPlan</label>
        <Select
          style={{ width: "100%" }}
          defaultValue={pplanNames[pplan]}
          onChange={(e) => handleInputChange(e, "pplan")}
        >
          <Option value="0">Gratuito</Option>
          <Option value="2">Light Quadri</Option>
          <Option value="3">Light Anual</Option>
          <Option value="4">Pro Quadri</Option>
          <Option value="5">Pro Anual</Option>
          <Option value="6969">Vitalício</Option>
        </Select>
      </div>

      <div style={{ marginTop: 25 }}>
        <label>Subscription Date</label>
        <Input
          value={subscription?.created_at}
          defaultValue={subscription?.created_at}
          onChange={() => {}}
          disabled
        />
      </div>

      <div style={{ marginTop: 25 }}>
        <label>Cancellation Date</label>
        <Input
          value={feedback ? feedback.createdAt : ""}
          defaultValue={feedback ? feedback.createdAt : ""}
          onChange={() => {}}
          disabled
        />
      </div>

      <div style={{ marginTop: 25 }}>
        <label>Cancellation Feedback</label>
        <Input.TextArea
          rows={4}
          value={
            feedback
              ? `${feedback.review} - ${JSON.stringify(feedback.score)}`
              : ""
          }
          defaultValue={
            feedback
              ? `${feedback.review} - ${JSON.stringify(feedback.score)}`
              : ""
          }
          onChange={() => {}}
          disabled
        />
      </div>
      <div style={{ marginTop: 25 }}>
        <Button key="back" onClick={closeModal}>
          Cancel
        </Button>
        ,
        <Button type="primary" onClick={editPPlan}>
          Edit
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionForm;
