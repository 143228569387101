import jwt_decode from "jwt-decode";

export const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getObjectFromCookie = (cname) => {
  const obj = getCookie(cname);

  if (obj === "undefined") {
    return null;
  }

  if (obj) {
    const obj_parsed = obj;
    return obj_parsed;
  }

  return null;
};

export const deleteCookie = (cname) => {
  document.cookie = `${cname}=; path=/; Max-Age=0`;
};

export const getUserDetailsFromCookie = () => {
  const session = getCookie("_session");
  if (session) {
    return jwt_decode(session);
  }

  return null;
};
