import React from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import PostsList from "../posts/PostsList";
import ShortLinks from "../shortLinks/ShortLinks";
import CreatePost from "../posts/CreatePost";
import UpdatePost from "../posts/UpdatePost";
import Graficos from "../graficos/Graficos";
import NewGraficos from "../graficos/NewGraficos";
import UpdateGraficos from "../graficos/UpdateGraficos";
import CuratedProfiles from "../curatedProfiles/CuratedProfiles";
import Videos from "../videos/Videos";
import PrivateRoute from "./PrivateRoute";
import { getUserDetailsFromCookie } from "../../utils/cookies";
import Home from "../Home";
import UsersAndPermissions from "../permissions/UsersAndPermissions";
import AdminRoute from "./AdminRoute";
import NotFound from "./NotFound";
import Tracks from "../tracks/Tracks";
import Chapter from "../chapters/Chapter";
import Badges from "../badges/Badges";
import Pdfs from "../Pdfs/Pdfs";
import Modules from "../modules/Modules";
import Feedback from "../feedback/Feedback";
import CoinsList from "../coinsList/CoinsList";
import Web3 from "../web3/Web3";
import MKTEmailList from "../mkt_email/MKTEmailList";
import CreateMKTEmail from "../mkt_email/CreateMKTEmail";
import ReferralList from "../referral/ReferralList";
import UpdateMKTEmail from "../mkt_email/UpdateMKTEmail";

const { Content: AntContent } = Layout;

const Content = () => {
  const user = getUserDetailsFromCookie();
  return (
    <AntContent className="content">
      <Switch>
        <PrivateRoute
          exact
          path="/"
          component={() => <Home user={user ? user : {}} />}
        />
        <PrivateRoute
          exact
          path="/posts"
          component={() => <PostsList user={user ? user : {}} />}
        />

        <PrivateRoute
          exact
          path="/posts/new"
          component={() => <CreatePost user={user ? user : {}} />}
        />
        <PrivateRoute
          exact
          path="/posts/:id/edit"
          component={() => <UpdatePost user={user ? user : {}} />}
        />
        <PrivateRoute
          exact
          path="/mkt-emails"
          component={() => <MKTEmailList user={user ? user : {}} />}
        />
        <PrivateRoute
          exact
          path="/mkt-emails/new"
          component={() => <CreateMKTEmail user={user ? user : {}} />}
        />
        <PrivateRoute
          exact
          path="/mkt-emails/:id/edit"
          component={() => <UpdateMKTEmail user={user ? user : {}} />}
        />
        <PrivateRoute
          exact
          path="/short-links"
          component={() => <ShortLinks user={user ? user : {}} />}
        />
        <AdminRoute
          exact
          path="/graficos"
          component={() => <Graficos user={user ? user : {}} />}
        />
        <AdminRoute
          exact
          path="/graficos/new"
          component={() => <NewGraficos />}
        />
        <AdminRoute
          exact
          path="/graficos/:id/edit"
          component={() => <UpdateGraficos user={user ? user : {}} />}
        />
        <AdminRoute
          exact
          path="/curated-profiles"
          component={() => <CuratedProfiles user={user ? user : {}} />}
        />
        <PrivateRoute
          exact
          path="/videos"
          component={() => <Videos user={user ? user : {}} />}
        />
        <AdminRoute
          exact
          path="/referral"
          component={() => <ReferralList user={user ? user : {}} />}
        />
        <AdminRoute
          exact
          path="/permissions"
          component={() => <UsersAndPermissions user={user ? user : {}} />}
        />
        <PrivateRoute
          exact
          path="/tracks"
          component={() => <Tracks user={user ? user : {}} />}
        />
        <PrivateRoute
          exact
          path="/modules"
          component={() => <Modules user={user ? user : {}} />}
        />
        <AdminRoute
          exact
          path="/badges"
          component={() => <Badges user={user ? user : {}} />}
        />
        <AdminRoute
          exact
          path="/pdfs"
          component={() => <Pdfs user={user ? user : {}} />}
        />
        <AdminRoute
          exact
          path="/coins-list"
          component={() => <CoinsList user={user ? user : {}} />}
        />
        <AdminRoute
          exact
          path="/modules/:id"
          component={() => <Chapter user={user ? user : {}} />}
        />
        <AdminRoute
          exact
          path="/feedback"
          component={() => <Feedback user={user ? user : {}} />}
        />
        <AdminRoute
          exact
          path="/web3"
          component={() => <Web3 user={user ? user : {}} />}
        />
        <Route component={NotFound} />
      </Switch>
    </AntContent>
  );
};

export default Content;
