import {
  GET_MKT_EMAILS,
  GET_MKT_EMAIL,
  ADD_MKT_EMAIL,
  DELETE_MKT_EMAIL,
  UPDATE_MKT_EMAIL,
} from "../actions/types";

const initialState = {
  MKTEmails: [],
  currentMKTEmail: null,
  error: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MKT_EMAILS:
      return {
        ...state,
        MKTEmails: action.payload,
      };
    case GET_MKT_EMAIL:
      return {
        ...state,
        currentMKTEmail: action.payload,
      };
    case ADD_MKT_EMAIL:
      return {
        ...state,
        MKTEmails: [...state.MKTEmails, action.payload],
        currentMKTEmail: action.payload,
      };
    case UPDATE_MKT_EMAIL:
      return {
        ...state,
        MKTEmails: state.MKTEmails.map((MKTEmail) =>
          MKTEmail.id === action.payload.id ? action.payload : MKTEmail
        ),
        currentMKTEmail: action.payload,
      };
    case DELETE_MKT_EMAIL:
      return {
        ...state,
        MKTEmails: state.MKTEmails.filter((MKTEmail) => MKTEmail.id !== action.payload),
      };
    default:
      return state;
  }
}
