import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";
import {
  getReferrals,
  searchReferrals,
} from "../../store/actions/referralActions";
import { Table, Spin, Button, Input } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { format, addDays, subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const { Search } = Input;

const columns = [
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.status.localeCompare(b.status),
  },
  {
    title: "DataCompra",
    dataIndex: "paid_at",
    key: "paid_at",
    sorter: (a, b) => new Date(a.paid_at) - new Date(b.paid_at),
    render:  (paid_at) => moment(paid_at).format("DD/MM/YYYY HH:mm:ss"),
  },
  {
    title: "MeioPagamento",
    dataIndex: "variables",
    key: "variables",
    sortDirections: ["ascend", "descend"],
      sorter: (a, b) => {
        let paymentMethodA = a.variables.find(item => item.variable === "payment_method")?.value || "";
        let paymentMethodB = b.variables.find(item => item.variable === "payment_method")?.value || "";
        return paymentMethodA.localeCompare(paymentMethodB);
      },
    render: (variables) => 
      variables.find(item => item.variable === "payment_method")?.value,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: "ValorPago",
    dataIndex: "total",
    key: "total",
    sorter: (a, b) => Number(a.total) - Number(b.total),
  },
  
  {
    title: "Estado",
    dataIndex: "payer_address_state",
    key: "payer_address_state",
    sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.payer_address_state.localeCompare(b.payer_address_state),
  },
  {
    title: "Descrição",
    dataIndex: "items",
    key: "items",
    render: (items) => (
      <span
        dangerouslySetInnerHTML={{
          __html: items.map((item) => item.description).join("<br />"),
        }}
      />
    ),
  },
];

const csvHeaders = [
  { label: "Status", key: "status" },
  { label: "Data Compra", key: "paid_at" },
  { label: "Meio Pagamento", key: "variable" },
  { label: "Email", key: "email" },
  { label: "Valor Pago", key: "total" },
  { label: "Estado", key: "payer_address_state" },
  { label: "Descrição", key: "item_descriptions" },
];

const ReferralList = ({ 
  referrals, 
  getReferrals, 
  searchReferrals, 
  searchResult,
 }) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedResults, setSearchedResults] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [allowedRange, setAllowedRange] = useState(null);

  const dataCVS = referrals.map(data => {
    const paid_at = format(new Date(data.paid_at), "dd/MM/yyyy HH:mm:ss");
    const variable = data.variables.find(item => item.variable === "payment_method")?.value;
    const itemDescription = data.items.map(item => item.description).join("; ");

    return {
      status: data.status,
      paid_at: paid_at,
      variable: variable,
      email: data.email,
      total: data.total,
      payer_address_state: data.payer_address_state,
      item_descriptions: itemDescription
    };
  });

  const csvReport = {
    data: dataCVS,
    headers: csvHeaders,
    filename: "Compras_cupons.csv",
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getReferrals();
      setLoading(false);
    };
    getData();
  }, [getReferrals]);

  useEffect(() => {
    if (searchResult) {
      setSearchedResults(searchResult || []);
    }
  }, [searchResult]);

  const handleDateChange = async (dates) => {
    setLoading(true);

    const [start, end] = dates;
  
    setStartDate(start);
    setEndDate(end);
  
    if (!start && !end) {
      setSearchedResults([]);
      setLoading(false);
      return;
    }
  
    if (start) {
      setAllowedRange({
        start: subDays(start, 30),
        end: addDays(start, 30),
      });
    } else {
      setAllowedRange(null);
    }
  
    const formattedStart = start ? moment(start).format("YYYY-MM-DD") : null;
    const formattedEnd = end ? moment(end).format("YYYY-MM-DD") : null;
  
    if (formattedStart && formattedEnd) {
      await searchReferrals(searchText, [formattedStart, formattedEnd]); 
    }
  
    setLoading(false);
  };

  const onSearch = async (value) => {
    if (value) {
      await searchReferrals(value);
    } else {
      setSearchedResults([])
    }
  };

  const handleSearchChange = (e) => {
    if (e.target.value === "") {
      setSearchedResults([]);
    }
    setSearchText(e.target.value);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex" }}>
          <Button type="primary" size="middle">
            <CSVLink {...csvReport}><SmileOutlined type="file" /> Exportar</CSVLink>
          </Button>
          <Search
            size="middle"
            value={searchText}
            placeholder="Search here.."
            onSearch={onSearch}
            style={{ width: 200 }}
            onChange={handleSearchChange}
          />
        </div>
        <div style={{ display: "flex" }}>
          <DatePicker 
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            includeDateIntervals={allowedRange ? [allowedRange] : undefined}
            className="custom-datepicker"
            placeholderText="Selecione as datas"
            dateFormat="dd/MM/yyyy"
            isClearable
          />
        </div>
      </div>
      <Spin spinning={loading}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={
            searchedResults.length > 0
              ? searchedResults
              : Array.isArray(referrals)
              ? referrals
              : []
            }
        />
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({
  referrals: state.referrals.referrals,
  searchResult: state.referrals.searchResult,
  searchError: state.referrals.searchError,
});

export default connect(mapStateToProps, { 
  getReferrals, 
  searchReferrals,
})(ReferralList);
