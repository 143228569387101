import { storage } from "../config/firebase";
import { getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { message } from "antd";

const randomString = (length, chars, result) => {
  for (var i = length; i > 0; --i)
    result += chars[Math.round(Math.random() * (chars.length - 1))];
  return result;
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const videoBeforeUploadFunc = (setFunctionPayload, setFunction) => {
  const isMP4 = setFunctionPayload.videoUpload.file.type === "video/mp4";

  if (!isMP4) {
    message.error("You can only upload mp4 video files");
    setFunction({
      ...setFunctionPayload,
      videoUpload: null,
      videoUploadError: "You can only upload mp4 video files",
    });
    return false;
  }
  const isLt1GB = setFunctionPayload.videoUpload.file.size <= 15000000000;
  if (!isLt1GB) {
    message.error("Video must be smaller than 1.5 GB!");
    setFunction({
      ...setFunctionPayload,
      videoUpload: null,
      videoUploadError: "Video must be smaller than 1.5 GB!",
    });
    return false;
  }

  setFunction({
    ...setFunctionPayload,
    videoUploadError: null,
  });
  return isMP4 && isLt1GB;
};

const uploadLargeFileHelper = async (
  setFunctionPayload,
  setFunction,
  fileType,
  fileLocation,
) => {
  const location = fileLocation ? fileLocation : "videos/";
  const fileToUploadURL = fileType === "pdf" ? "pdfURL" : "videoURL";
  const fileToUploadFile = fileType === "pdf" ? "pdfUpload" : "videoUpload";
  const fileToUploadProgress =
    fileType === "pdf" ? "pdfUploadProgress" : "videoUploadProgress";
  const fileToUploadInProgress =
    fileType === "pdf" ? "isPdfUploadInProgress" : "isVideoUploadInProgress";
  const fileToUploadError =
    fileType === "pdf" ? "pdfUploadError" : "videoUploadError";

  try {
    let fileRef;
    const file = setFunctionPayload[fileToUploadFile].file;
    const storageRef = storage.ref();
    const fileName = randomString(
      16,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
      file.name,
    );
    fileRef = storageRef.child(`${location}${fileName}`);
    const uploadTask = uploadBytesResumable(fileRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setFunction({
          ...setFunctionPayload,
          [fileToUploadProgress]: Math.trunc(progress),
          [fileToUploadInProgress]: true,
        });
      },
      (error) => {
        setFunction({
          ...setFunctionPayload,
          [fileToUploadError]: "failed to upload to file",
          [fileToUploadInProgress]: false,
        });
      },
      () => {
        return getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFunction({
            ...setFunctionPayload,
            [fileToUploadURL]: downloadURL,
            [fileToUploadProgress]: 100,
            [fileToUploadInProgress]: false,
            [fileToUploadError]: null,
          });
        });
      },
    );
  } catch (error) {
    return error;
  }
};

const uploadVideoToFirebase = ({ chapterPayload, setChapterPayload }) => {
  try {
    let fileRef;
    const file = chapterPayload.videoUpload.file;
    const storageRef = storage.ref();
    const fileName = randomString(
      16,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
      file.name,
    );
    fileRef = storageRef.child(`videos/video-trackers/public/${fileName}`);
    const uploadTask = uploadBytesResumable(fileRef, file.originFileObj);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setChapterPayload({
          ...chapterPayload,
          videoProgress: Math.trunc(progress),
          isVideoInProgress: true,
        });
      },
      (error) => {
        setChapterPayload({
          ...chapterPayload,
          videoUploadError: "failed to upload to video file",
          isVideoInProgress: false,
        });
      },
      () => {
        return getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setChapterPayload({
            ...chapterPayload,
            videoProgress: 100,
            isVideoInProgress: false,
            videoURL: downloadURL,
            videoUploadError: "",
          });
        });
      },
    );
  } catch (error) {
    return error;
  }
};

const uploadOtherFilesToFirebase = async (file, fileType) => {
  try {
    let fileRef = "";
    const fileName = `${randomString(
      16,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
      "",
    )}.${file.name.split(".").pop()}`;
    const storageRef = storage.ref();
    if (fileType === "pdf") {
      fileRef = storageRef.child(`pdfs/${fileName}`);
    } else if (fileType === "badges") {
      fileRef = storageRef.child(`badges/${fileName}`);
    } else {
      fileRef = storageRef.child(`thumbnails/${fileName}`);
    }
    await fileRef.put(file.originFileObj);
    return `https://paradigma.education/cdn-cgi/image/quality=75/https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${fileRef.fullPath}`;
  } catch (error) {
    return error;
  }
};

const uploadImagesToFirebase = async (file) => {
  try {
    let fileRef = "";
    const fileName = randomString(
      16,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
      file.name,
    );
    const storageRef = storage.ref();
    fileRef = storageRef.child(`pdfs/thumbnails/${fileName}`);
    await fileRef.put(file.originFileObj);
    return `https://paradigma.education/cdn-cgi/image/quality=75/https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${fileRef.fullPath}`;
  } catch (error) {
    return error;
  }
};

const beforeThumbnailUpload = (file) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";
  if (!isJpgOrPng) {
    message.error("You can only upload a JPG/PNG file!");
    return false;
  }
  const isLt2M = file.size <= 3000000;
  if (!isLt2M) {
    message.error("Image must be smaller than 2MB!");
    return false;
  }

  return isJpgOrPng && isLt2M;
};

export {
  uploadOtherFilesToFirebase,
  uploadImagesToFirebase,
  uploadVideoToFirebase,
  beforeThumbnailUpload,
  uploadLargeFileHelper,
  videoBeforeUploadFunc,
};
