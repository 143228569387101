import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import parse from "html-react-parser";
import "react-quill/dist/quill.snow.css";
import { updMKTEmail, getMKTEmail } from "../../store/actions/MKTEmailActions";
import { Form, Input, Button, Row, Col, Select, Spin, message } from "antd";

const { Option } = Select;

const categories = [
  { name: "Vitalício", value: 6969 },
  { name: "Pro", value: 5 },
  { name: "Light", value: 3 },
  { name: "Team", value: 9393 },
];

const UpdateMKTEmail = ({ updMKTEmail, getMKTEmail, MKTEmail }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");
  const [fields, setFields] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getMKTEmail(id);
        await fetchTemplates();
      } catch (error) {
        message.error("Failed to load data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, getMKTEmail]);

  const fetchTemplates = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_GOLANG_API}/api/v2/sendgrid`,
      );
      setTemplates(data);
    } catch (error) {
      message.error("Failed to fetch templates");
    }
  };

  useEffect(() => {
    if (MKTEmail) {
      setText(MKTEmail.body);
      setFields(
        Object.entries(MKTEmail).map(([key, value]) => ({
          name: key,
          value,
        })),
      );
    }
  }, [MKTEmail]);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await updMKTEmail(id, { ...values, body: text }, text);
      message.success("Email updated successfully");
      history.push("/mkt-emails"); // Replace with the correct path
      window.location.reload();
    } catch (error) {
      message.error("Failed to update email");
    } finally {
      setLoading(false);
    }
  };

  const handleGetTemplate = async () => {
    if (!selectedTemplateId) {
      message.warning("Please select a template");
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_GOLANG_API}/api/v2/sendgrid/${selectedTemplateId}`,
      );
      setText(data);
    } catch (error) {
      message.error("Failed to get template content");
    } finally {
      setLoading(false);
    }
  };

  if (!MKTEmail) return null;

  return (
    <Form
      form={form}
      name="updateEmailForm"
      onFinish={handleSubmit}
      fields={fields}
      layout="vertical"
      onFieldsChange={(_, allFields) => setFields(allFields)}
    >
      <Spin spinning={loading}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="subject"
              label="Subject"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="Subject" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="templateId" label="Template ID">
              <Select
                placeholder="Select a template"
                onChange={setSelectedTemplateId}
                value={selectedTemplateId}
                allowClear
              >
                {templates.map((template) => (
                  <Option key={template.id} value={template.id}>
                    {template.name}
                  </Option>
                ))}
              </Select>
              <Button
                type="primary"
                onClick={handleGetTemplate}
                style={{ marginTop: 10 }}
              >
                Get Template
              </Button>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Content">
              <textarea
                rows={10}
                style={{
                  width: "100%",
                  border: "1px solid #d9d9d9",
                  outline: "none",
                }}
                value={text}
                onChange={(e) =>
                  setText(() => {
                    return e.target.value;
                  })
                }
              />
              {text && (
                <div style={{ marginTop: 16 }}>
                  <label className="ant-form-item-label">Preview :</label>
                  <div
                    style={{
                      border: "1px solid #ccc",
                      padding: 10,
                      minHeight: 150,
                      maxHeight: 500,
                      overflow: "auto",
                    }}
                  >
                    {parse(text)}
                  </div>
                </div>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="pplans"
              label="Plans"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Please select"
                mode="multiple"
              >
                {categories.map((cat) => (
                  <Option value={cat.value} key={cat.value}>
                    {cat.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{
                display: "block",
                marginLeft: "auto",
                width: 100,
              }}
            >
              Update
            </Button>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  MKTEmail: state.MKTEmails.currentMKTEmail,
});

export default connect(mapStateToProps, {
  updMKTEmail,
  getMKTEmail,
})(UpdateMKTEmail);
