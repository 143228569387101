import { 
  GET_REFERRALS, 
  REFERRALS_ERROR, 
  SEARCH_REFERRALS_ERROR, 
  SEARCH_REFERRALS_SUCCESS 
} from "../actions/types";

const initialState = {
  referrals: [],
  error: null,
  searchResult: null,
  searchError: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REFERRALS:
      return {
        ...state,
        referrals: action.payload,
      };
    case SEARCH_REFERRALS_SUCCESS:
      return {
        ...state,
        searchResult: action.payload,
      };
    case SEARCH_REFERRALS_ERROR:
      return {
        ...state,
        searchError: action.payload,
      };
    case REFERRALS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
