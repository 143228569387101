import React from "react";
import { Alert, Input, Select, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
//eslint-disable-next-line
import MuxUploader from "@mux/mux-uploader-react";

import { beforeThumbnailUpload } from "../../../utils/uploadFileToFirebase";

const Option = Select.Option;

const VideoForm = (props) => {
  const {
    handleInputChange,
    handleSelectChanger,
    difficulty,
    tagIds,
    tags,
    pdfs,
    pdfIds,
    name,
    description,
    mp4url,
    videoId,
    videoUploadPayload,
    changeThumbnail,
    thumbnailURL,
    thumbnailUploadError,
    pdfUploadError,
    formError,
    muxUrl,
  } = props;

  const { videoUploadError } = videoUploadPayload;

  return (
    <div>
      {videoUploadError ? (
        <div style={{ marginBottom: 25 }}>
          <Alert type="error" message={videoUploadError} />
        </div>
      ) : null}

      {thumbnailUploadError ? (
        <div style={{ marginBottom: 25 }}>
          <Alert type="error" message={thumbnailUploadError} />
        </div>
      ) : null}

      {formError ? (
        <div style={{ marginBottom: 25 }}>
          <Alert type="error" message={formError} />
        </div>
      ) : null}

      {pdfUploadError ? (
        <div style={{ marginBottom: 25 }}>
          <Alert type="error" message={pdfUploadError} />
        </div>
      ) : null}

      <div className="video-container">
        <label>Preview</label>
        <mux-uploader endpoint={muxUrl}></mux-uploader>
      </div>
      {mp4url ? (
        <div style={{ marginTop: 40 }}>
          <video
            src={mp4url}
            controls
            style={{
              width: "100%",
              height: "320px",
              backgroundSize: "cover",
            }}
          />
        </div>
      ) : null}

      <div style={{ marginTop: 25 }}>
        <label>Or Enter YouTube URL:</label>
        <Input
          value={videoId}
          defaultValue={videoId}
          placeholder="https://www.youtube.com/watch?v=example"
          onChange={(e) => handleInputChange(e, "videoId")}
        />
        {videoId ? (
        <div style={{ marginTop: 10 }}>
          <iframe
            width="100%"
            height="320"
            src={`https://www.youtube.com/embed/${videoId.split('v=')[1]}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ) : null}
      </div>

      <div style={{ marginTop: 25 }}>
        <label>Name</label>
        <Input
          value={name}
          defaultValue={name}
          onChange={(e) => handleInputChange(e, "name")}
        />
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Description</label>
        <Input.TextArea
          rows={4}
          placeholder="Enter description here"
          value={description}
          defaultValue={description}
          onChange={(e) => handleInputChange(e, "description")}
        />
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Thumbnail</label>
        <div className="thumbnail-container">
          <ImgCrop
            grid
            rotate
            aspect={16 / 9}
            quality={0.8}
            crop={{ x: 0, y: 0 }}
          >
            <Upload
              name="thumbnail"
              listType="picture-card"
              showUploadList={false}
              accept="image/png, image/jpg, image/jpeg"
              beforeUpload={beforeThumbnailUpload}
              onChange={changeThumbnail}
            >
              {thumbnailURL ? (
                <img
                  src={thumbnailURL}
                  alt="avatar"
                  style={{ width: "100%", height: "200px" }}
                />
              ) : (
                <PlusOutlined />
              )}
            </Upload>
          </ImgCrop>
        </div>
      </div>

      <div style={{ marginTop: 25 }}>
        <label>Difficulty</label>
        <Select
          value={difficulty}
          defaultValue={difficulty}
          style={{ width: "100%" }}
          placeholder="Inciante"
          onChange={(val) => handleSelectChanger(val, "difficulty")}
        >
          <Option value="Iniciante">Iniciante</Option>
          <Option value="Médio">Médio</Option>
          <Option value="Avançado">Avançado</Option>
        </Select>
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Tags</label>
        <Select
          allowClear
          value={tagIds}
          defaultValue={tagIds}
          mode="tags"
          style={{ width: "100%" }}
          placeholder="tags"
          onChange={(val) => handleSelectChanger(val, "tags")}
        >
          {tags && tags.length > 0
            ? [...new Set(tags)].map((tag) => {
                return (
                  <Option value={tag.id} key={tag.id}>
                    {tag.name}
                  </Option>
                );
              })
            : null}
        </Select>
      </div>

      <div style={{ marginTop: 15 }}>
        <label>PDFs</label>
        <Select
          allowClear
          mode="multiple"
          value={pdfIds}
          defaultValue={pdfIds}
          style={{ width: "100%" }}
          placeholder=""
          onChange={(val) => handleSelectChanger(val, "isPdfs")}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {pdfs && pdfs.length > 0
            ? pdfs.map((pdf, idx) => {
                return (
                  <Option value={pdf.id} key={pdf.id}>
                    {pdf.name}
                  </Option>
                );
              })
            : null}
        </Select>
      </div>
    </div>
  );
};

export default VideoForm;
