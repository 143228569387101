import axios from "axios";
import { message } from "antd";
import {
  FETCH_ALL_USERS,
  FAILED_ALL_USER_FETCH,
  UPDATE_USER_PERMISSION,
  UPDATE_USER_CLEARANCELEVEL,
  FAILED_USER_UPDATE_PERMISSION,
  FETCH_ALL_ORDERS,
  FETCH_ALL_LEADS,
  FAILED_TO_FETCH_ALL_ORDERS,
} from "./types";
import { getCookie } from "../../utils/cookies";

export const fetchAllUsers = (page) => async (dispatch) => {
  try {
    const token = getCookie("_session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(token)}`;
    const resp = await axios.get(
      `${process.env.REACT_APP_BACKEND_APP_API}/users?pageSize=10&page=${page}`
    );
    return dispatch({
      type: FETCH_ALL_USERS,
      payload: resp.data,
    });
  } catch (error) {
    return dispatch({
      type: FAILED_ALL_USER_FETCH,
      payload: "",
    });
  }
};

export const getUserByEmail = (email) => async (dispatch) => {
  try {
    const token = getCookie("_session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(token)}`;
    const resp = await axios.get(
      `${process.env.REACT_APP_BACKEND_APP_API}/users?email=${email}`
    );
    return dispatch({
      type: FETCH_ALL_USERS,
      payload: {totalCount: [{count: 1}], users: [resp.data]},
    });
  } catch (error) {
    return dispatch({
      type: FAILED_ALL_USER_FETCH,
      payload: "",
    });
  }
};

export const fetchAllOrders = () => async (dispatch) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/orders/8-daysago`
    );
    return dispatch({
      type: FETCH_ALL_ORDERS,
      payload: resp.data.data,
    });
  } catch (error) {
    return dispatch({
      type: FAILED_TO_FETCH_ALL_ORDERS,
      payload: [],
    });
  }
};

export const fetchAllLeads = () => async (dispatch) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/leads`
    );
    return dispatch({
      type: FETCH_ALL_LEADS,
      payload: resp.data.leads,
    });
  } catch (error) {
    return dispatch({
      type: FAILED_TO_FETCH_ALL_ORDERS,
      payload: [],
    });
  }
};

export const fetchSalesReport = () => async (dispatch) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/orders/sales-report`
    );
    return dispatch({
      type: "FETCH_SALES_REPORT",
      payload: resp.data.data,
    });
  } catch (error) {
    return dispatch({
      type: FAILED_TO_FETCH_ALL_ORDERS,
      payload: [],
    });
  }
};

export const updateUserPPlan = (payload) => async (dispatch) => {
  try {
    const token = getCookie("_session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(token)}`;
    await axios.patch(
    `${process.env.REACT_APP_BACKEND_APP_API}/users/${payload.id}/pplan`,
    payload
    );
  } catch (error) {
      return dispatch({
      type: FAILED_USER_UPDATE_PERMISSION,
      payload: "",
    });
  }
}

export const updateUserDiscordServer = (payload) => async (dispatch) => {
  try {
    await axios.post(
    `https://discord-bot.paradigma.education/webhook`, { 
      ...payload 
    },
      { 
        headers: {
        'content-type': 'application/json',
        'x-integration-key': process.env.REACT_APP_KEY_DISCORD,
        }
      },
    );
  } catch (error) {
      return dispatch({
      type: FAILED_USER_UPDATE_PERMISSION,
      payload: "",
    });
  }
}

export const updateUserPermission =
  (payload, operation, type) => async (dispatch) => {
    try {
      const resp = await axios.patch(
        `${process.env.REACT_APP_BACKEND_APP_API}/users/${payload.id}/${payload.action}`,
      );

      message.success("Atualizado com sucesso.");

      return dispatch({
        type: UPDATE_USER_PERMISSION,
        payload: resp.data.message,
      });
    } catch (error) {
      message.error("Erro ao atualizar.");

      return dispatch({
        type: FAILED_USER_UPDATE_PERMISSION,
        payload: "",
      });
    }
  };

  export const updateUserClearanceLevel =
  (payload) => async (dispatch) => {
    try {
      const resp = await axios.patch(
        `${process.env.REACT_APP_BACKEND_APP_API}/users/${payload.id}/clearance`,
        {
          clearanceLevel: payload.clearanceLevel
        }
      );

      message.success("Atualizado com sucesso.");

      return dispatch({
        type: UPDATE_USER_CLEARANCELEVEL,
        payload: resp.data.message,
      });
    } catch (error) {
      message.error("Erro ao atualizar.");

      return dispatch({
        type: FAILED_USER_UPDATE_PERMISSION,
        payload: error.message,
      });
    }
  };
